import App from './app.vue'
import { siteConfig } from './config'
import { routes } from './router'
import { setupMainStandard } from '@/main-standard'

import '@/styles/index.styl'
import './styles/theme.styl'

// https://github.com/antfu/vite-ssg
export const createApp = setupMainStandard({
  appComponent: App,
  routes,
  siteConfig,
  async setup(ctx) {
    const components = await import('./components')
    await components.install(ctx)
  },
})
